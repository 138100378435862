import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
//----------------------

require('./lib/owl.carousel');


$(document).foundation();

function initPage(apage) {
    console.log('page is ' + apage);
}

$(document).ready(function () {

    /*------homepage carousels --------------*/
    if ($('.homepage').length > 0) initHome();



    /*------resource carousels --------------*/
    if ($('.resourcepage').length > 0) initResources(); 


    /*------external link modal ----------*/

    $('.extlink').on('click', function (e) {
        e.preventDefault();
        let elink = $(this).attr('link');
        if (elink=="" || elink==undefined) return;
        if ($(this).hasClass("nomodal")){
            window.open(elink, '_blank');
            return;
        } else if ($(this).hasClass("nomodal2")){
            window.open(elink, '_self');
            return;
        }
        let modid = $(this).attr('modid');
        let etitle = $(this).attr('etitle');
        let tid = $(this).attr('tid');
        if (etitle!="" && etitle!==undefined){
            $('#' + modid + " #linktitle").html(etitle);
        }
        if (tid!="" && tid!==undefined){
            $('#' + modid + " span.msg").removeClass("show");
            $('#' + modid + " #t"+tid).addClass("show");
        }
        if ($(this).hasClass("dep")){
            $('#ptype').html(" with depression");
        } else if ($(this).hasClass("adv")){
            $('#ptype').html("");
        }
        $('#' + modid + " .continue").attr("href", elink);
        $('#' + modid).foundation('open');

    });
    $('.reveal .continue').on('click', function () {
        const url = $(this).attr("href");
        $(this).closest('.reveal').foundation('close');
        window.open(url, '_blank');
        //window.open($(this).attr("href"), "_blank");
    });

    /*-------- facebook share button -----------*/
    $(".facebook").on("click",function(){
        var fbpopup = window.open("https://www.facebook.com/sharer/sharer.php?u=https://www.depressionlookslikeme.com/", "pop", "width=600, height=400, scrollbars=no");
        return false;
    });
    $(".facebookStory").on("click",function(){
        var fbpopup = window.open("https://www.facebook.com/sharer/sharer.php?u=https://www.depressionlookslikeme.com/", "pop", "width=600, height=400, scrollbars=no");
        return false;
    });
    $(".facebookKalei").on("click",function(){
        var fbpopup = window.open("https://www.facebook.com/sharer/sharer.php?u=https://www.depressionlookslikeme.com/our-kaleidoscope/", "pop", "width=600, height=400, scrollbars=no");
        return false;
    });

    /*-------- Kaleidoscope Video Controls -----------*/
    //For Firefox we have to handle it in JavaScript 
    var vids = $("video"); 
    $.each(vids, function(){
           this.controls = false; 
    }); 
    //Loop though all Video tags and set Controls as false

    $("video").click(function() {
      //console.log(this); 
      if (this.paused) {
        this.play();
      } else {
        this.pause();
      }
    });

    /*-------- story form submit  -----------*/
   $("#storyform").on("submit", function(e){
        e.preventDefault();
   })
   .on("formvalid.zf.abide", function (e){
       //handle form submit
        document.getElementById("storyform").reset();
        $('#formSuccessModal').foundation('open');
        console.log("e.type was "+e.type);
        //e.stopImmediatePropagation();
        //return false;
    });
});

/*---------------------------------*/

function initHome() {
    const myowl = $('.owl-carousel.storycards').owlCarousel({
        loop:false,
        margin:16,
        nav:false,
        responsive:{
            0:{
                items:1,
                loop:true
            },
            640:{
                items:3,
                loop:false
            }
        }
    });

   const resowl = $('.home-resource-carousel').owlCarousel({
        loop:false,
        nav:false,
        responsive:{
            0:{
                items:1,
                margin:16,
                loop:true
            },
            640:{
                items:3,
                margin:32,
                loop:false
            }
        }
    });
}

/*---------------------------------*/

function initResources() {
    let w = $(window).width();
    if (w<640){
        const hcowl = $('.hcwrap').owlCarousel({
            loop:true,
            margin:16,
            nav:false,
            items:1,
            dots:true
        });
        const bpowl = $('.bipocwrap').owlCarousel({
            loop:true,
            margin:16,
            nav:false,
            items:1,
            dots:true
        });
        const trowl = $('.transwrap').owlCarousel({
            loop:true,
            margin:16,
            nav:false,
            items:1,
            dots:true
        });
        const gmhowl = $('.gmhwrap').owlCarousel({
            loop:true,
            margin:16,
            nav:false,
            items:1,
            dots:true
        });
        const supowl = $('.supwrap').owlCarousel({
            loop:true,
            margin:16,
            nav:false,
            items:1,
            dots:true
        });
        const groupowl = $('.groupwrap').owlCarousel({
            loop:true,
            margin:16,
            nav:false,
            items:1,
            dots:true
        });
    }

    /*-------------*/
   
}


    
